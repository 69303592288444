import React, { useState, useEffect, Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import isNil from "lodash/isNil";
import track, { useTracking } from "react-tracking";
import qs from "query-string";

import Typography from "@ui-kit/Typography";
import Dialog from "@ui-kit/Dialog";
import { createCss } from "./styles";
import { Input } from "@ui-kit/InputFields";
import SuccessStep from "@components/SuccessStep";
import Button from "@ui-kit/Button";
import { useDeepDivePanelStore, usePrevious } from "@hooks";
import { useUserStore } from "@store/UserStore";
import { ROUTES } from "@constants";
import { FACILITY_API } from "@services/api";
import {
  getFacilityFullAddress,
  getFormattedSchedule,
  formatYrsInBusiness,
  getSchoolAgeRange,
  getAvgClassSize,
} from "@selectors";
import { required } from "@validators";
import { getFullGradeString } from "@helpers/grades-helpers";
import {
  getEnrollmentCapacityString,
  getFeesString,
} from "@selectors/facility";
import { tuitionWaitlistMap } from "@constants/tuition-waitlist";
import { Spacer } from "@ui-kit/Spacer";
import { schoolTypesMap } from "@constants/school-types";

type FormFields = {
  email: string;
  name: string;
  recipient: string;
};

const ShareFacility: React.FC = () => {
  const { trackEvent } = useTracking();
  const { handleSubmit, errors, control, setError, formState } =
    useForm<FormFields>();
  const [{ user }] = useUserStore();
  const prevUser = usePrevious(user);
  const [{ facility }] = useDeepDivePanelStore();
  const history = useHistory();
  const { search } = useLocation();
  const css = createCss();
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (!prevUser && user) {
      void submitForm();
    }
  }, [user]);

  const checkAuthorization = handleSubmit(() => {
    if (!user) {
      history.replace({
        pathname: ROUTES.SIGNUP_FORM,
        search: qs.stringify({
          addressCountry: facility.address.country,
        }),
      });
    } else {
      void submitForm();
    }
  });

  const submitForm = handleSubmit(async ({ email, name, recipient }) => {
    try {
      trackEvent({ action: "Submit" });

      await FACILITY_API.facilitiesByIdShare({
        facilitiesShare: {
          firstName: name.split(" ")[0],
          lastName: name.split(" ")[1] || "",
          recipientEmail: email,
          recipientFirstName: recipient.split(" ")[0],
          recipientLastName: recipient.split(" ")[1] || "",
        },
        id: facility.id,
      });

      trackEvent({ action: "SubmitSuccess" });

      setStep(2);
    } catch (error) {
      trackEvent({ action: "SubmitError" });

      setError("email", {
        message: "Failed to send email",
        type: "manual",
      });
    }
  });

  const onClose = () => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  };

  return (
    <Dialog
      open
      onClose={onClose}
      title="Share school profile"
      data-test="share-school-modal"
      tier={facility.subscriptionTier}
      protectQuit={step === 1}
    >
      <div data-test="share-school-form" css={css.container}>
        {step === 1 && (
          <Fragment>
            <div>
              <Typography bolded align="center" gutterBottom>
                {facility.name}
              </Typography>
              <Typography align="center">
                {getFacilityFullAddress(facility)}
              </Typography>

              <div css={css.facilityData}>
                <Typography
                  gutterBottom
                  css={css.item}
                  data-test="facility-type-item"
                >
                  <Typography variant="inherit" bolded>
                    Facility type:
                  </Typography>{" "}
                  <span>{schoolTypesMap[facility.facilityType].title}</span>
                </Typography>

                <Typography gutterBottom css={css.item}>
                  <Typography variant="inherit" bolded>
                    Waitlist:
                  </Typography>{" "}
                  <span>
                    {!isNil(facility.tuitionInfo.waitlistProvided)
                      ? tuitionWaitlistMap[
                          facility.tuitionInfo.waitlistProvided
                        ].name
                      : "No"}
                  </span>
                </Typography>
                <Typography css={css.item} data-test="application-fees-item">
                  <Typography variant="inherit" bolded>
                    Application fee:
                  </Typography>{" "}
                  <span>
                    {getFeesString(facility, "feesApplication") || (
                      <Typography variant="inherit" color="error" italic>
                        missing
                      </Typography>
                    )}
                  </span>
                </Typography>

                <Spacer size="medium" />

                <Typography gutterBottom css={css.item}>
                  <Typography variant="inherit" bolded>
                    Operating hours:
                  </Typography>{" "}
                  <span>
                    {getFormattedSchedule(facility.schedule) || (
                      <Typography variant="inherit" color="error" italic>
                        missing
                      </Typography>
                    )}
                  </span>
                </Typography>
                <Typography gutterBottom css={css.item}>
                  <Typography variant="inherit" bolded>
                    Years in business:
                  </Typography>{" "}
                  <span>
                    {formatYrsInBusiness(facility) || (
                      <Typography variant="inherit" color="error" italic>
                        missing
                      </Typography>
                    )}
                  </span>
                </Typography>
                <Typography gutterBottom css={css.item}>
                  <Typography variant="inherit" bolded>
                    Enrollment capacity:
                  </Typography>{" "}
                  <span>
                    {getEnrollmentCapacityString(facility) || (
                      <Typography variant="inherit" color="error" italic>
                        missing
                      </Typography>
                    )}
                  </span>
                </Typography>

                <Spacer size="medium" />

                <Typography gutterBottom css={css.item}>
                  <Typography variant="inherit" bolded>
                    Ages served:
                  </Typography>{" "}
                  <span>
                    {getSchoolAgeRange(facility) || (
                      <Typography variant="inherit" color="error" italic>
                        missing
                      </Typography>
                    )}
                  </span>
                </Typography>
                <Typography
                  gutterBottom
                  css={css.item}
                  data-test="grades-served-item"
                >
                  <Typography variant="inherit" bolded>
                    Grades served:
                  </Typography>{" "}
                  <span>
                    {getFullGradeString(facility) || (
                      <Typography variant="inherit" color="error" italic>
                        missing
                      </Typography>
                    )}
                  </span>
                </Typography>
                <Typography
                  paragraph
                  css={css.item}
                  data-test="class-size-item"
                >
                  <Typography variant="inherit" bolded>
                    Avg. class size:
                  </Typography>{" "}
                  <span>
                    {getAvgClassSize(facility) ? (
                      `${getAvgClassSize(facility)} students`
                    ) : (
                      <Typography variant="inherit" color="error" italic>
                        missing
                      </Typography>
                    )}
                  </span>
                </Typography>
              </div>

              <Controller
                render={(controllerProps) => (
                  <Input
                    {...controllerProps}
                    fullWidth
                    error={errors.name?.message}
                    data-test="name-field"
                    label="Your name"
                  />
                )}
                rules={required()}
                name="name"
                defaultValue={user ? `${user.firstName} ${user.lastName}` : ""}
                control={control}
              />

              <Controller
                render={(controllerProps) => (
                  <Input
                    {...controllerProps}
                    fullWidth
                    error={errors.email?.message}
                    data-test="email-field"
                    label="Recipient email"
                  />
                )}
                name="email"
                defaultValue=""
                control={control}
                rules={required()}
              />

              <Controller
                render={(controllerProps) => (
                  <Input
                    {...controllerProps}
                    fullWidth
                    error={errors.recipient?.message}
                    data-test="recipient-name-field"
                    label="Recipient name"
                  />
                )}
                name="recipient"
                defaultValue=""
                control={control}
                rules={required()}
              />
            </div>
            <div css={css.footer}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                data-test="submit-btn"
                onClick={checkAuthorization}
                type="submit"
                loading={formState.isSubmitting}
              >
                Send
              </Button>
            </div>
          </Fragment>
        )}

        {step === 2 && (
          <SuccessStep text="School profile shared" onDone={onClose} />
        )}
      </div>
    </Dialog>
  );
};

export default track({
  page: "Share Facility",
})(ShareFacility);
